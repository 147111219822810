import React from 'react';
import ReactDOM from 'react-dom';
import { graphql } from 'gatsby';
import Section from '@shared/Section/Section';
import Footer from '@shared/Footer/Footer';
import Legal from '@shared/Legal/Legal.query';
import Meta from '@shared/Meta/Meta.query';
import Contact from '@components/sections/Contact/Contact.query';
import SimplifiedHeader from '@shared/SimplifiedHeader/SimplifiedHeader';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import { q } from '@utils';

class TermsPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: null
    };

    this.portalContainer = null;
  }

  componentDidMount() {
    this.portalContainer = q('#layout');
  }

  setPageSection = newSection => {
    this.setState({ currentSection: newSection });
  };

  render() {
    const { data } = this.props;
    const { currentSection } = this.state;
    const legal = data.contentfulLegalPage;
    const contact = data.allContentfulHome.edges.map(edge => edge.node.contact)[0];
    const meta = data.allContentfulHome.edges.map(edge => edge.node.meta)[0];
    return (
      <>
        {this.portalContainer &&
          ReactDOM.createPortal(
            <GradientBackground currentStep={currentSection} />,
            this.portalContainer
          )}

        <Meta {...meta} />
        <SimplifiedHeader currentStep={currentSection} />
        <Section title="Story" setPageSection={this.setPageSection}>
          <Legal {...legal} />
        </Section>
        <Section title="Contact" setPageSection={this.setPageSection}>
          <Contact {...contact} />
        </Section>
        <Footer currentGradientStep={currentSection} />
      </>
    );
  }
}

export default TermsPage;

// Graphql Query
export const pageQuery = graphql`
  {
    contentfulLegalPage(title: { eq: "Terms" }) {
      ...Legal
    }
    allContentfulHome {
      edges {
        node {
          contact {
            ...Contact
          }
          meta {
            ...Meta
          }
        }
      }
    }
  }
`;
