import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { css } from '@utils';

import styles from './Legal.module.scss';

const Legal = props => {
  const { headline, body } = props;
  const bodyElement = documentToReactComponents(body, {});

  return (
    <>
      <div className={css(styles.legal)}>
        <h2 className={css(styles.headline)}>{headline}</h2>
        <div className={css(styles.body)}>{bodyElement}</div>
      </div>
    </>
  );
};

export default Legal;
