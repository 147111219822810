import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Legal from './Legal';

const LegalQuery = props => {
  const headline = idx(props, _ => _.headline);
  const body = idx(props, _ => _.body.json);
  return <Legal body={body} headline={headline} />;
};

export default LegalQuery;

export const query = graphql`
  fragment Legal on ContentfulLegalPage {
    headline
    body {
      json
    }
  }
`;
